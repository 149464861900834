import { ChangeEvent, HTMLInputTypeAttribute, LegacyRef } from 'react';
import styles from './styles/input.module.scss';

interface Props {
    type: HTMLInputTypeAttribute;
    ref?: LegacyRef<HTMLInputElement>;
    name?: string;
    defaultValue?: string;
    value?: string;
    placeholder?: string;
    accept?: string;
    required?: boolean;
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
    disabled?: boolean;
    isError?: boolean;
    errorMessage?: string;
    pattern?: string;
    maxLength?: number;
}

const Input = ({
    type,
    ref,
    name,
    defaultValue,
    value,
    placeholder,
    accept,
    onChange,
    required,
    disabled,
    isError,
    errorMessage,
    pattern,
    maxLength
}: Props) => {
    const currentDate = new Date().toISOString().split('T')[0];

    return (
        <>
            {type === 'date' ? (
                <input
                    className={`${styles.input} ${isError ? styles.error_input : ''}`}
                    type={type}
                    ref={ref}
                    name={name}
                    disabled={disabled}
                    value={value}
                    defaultValue={defaultValue}
                    placeholder={placeholder}
                    accept={accept}
                    required={required}
                    onChange={onChange}
                    max={currentDate}
                />
            ) : (
                <input
                    className={`${styles.input} ${isError ? styles.error_input : ''}`}
                    type={type}
                    ref={ref}
                    name={name}
                    disabled={disabled}
                    value={value}
                    defaultValue={defaultValue}
                    placeholder={placeholder}
                    accept={accept}
                    required={required}
                    onChange={onChange}
                    pattern={pattern}
                    maxLength={maxLength}
                />
            )}
            {isError ? <p className={styles.error_req}>{errorMessage ? errorMessage : 'This field is required!'}</p> : null}
        </>
    );
};

export default Input;
