'use client';
import Link from 'next/link';
import { validPhoneNumber } from '@/services/utils/cmnHelper';
import { useRouter } from 'next/navigation';
import styles from './style.module.scss';
import { userStorageName } from '@/services/apis/config';
import { useEffect, useRef, useState } from 'react';
import { getDeviceType, updateUserCartRealTime } from '@/services/utils/clientHelper';
import toast from 'react-hot-toast';
import { useBseCartContext } from '@/services/context/user-cart';
import Input from '@/components/molecules/input';
import { R_PRIVACY_POLICY, R_RETURN_POLICY, R_TOS } from '@/services/utils/path';
import { postAuthSMSSend, postAuthSMSVerify } from '@/services/apis/api';
import { useRefPartnerApiContext } from '@/services/context/ref-partner-api';
import { useLabTestContext } from '@/lab-test/context/lab-test-context';
import { Open } from '@/services/types/lab-test';
import { useAlert } from '@/services/context/alert-state';
import Loading from '@/components/atoms/loading';
import SocialLogin from './social-login';

interface LoginCompo {
    goto?: string;
    setLoginModal?: any;
}

const LoginCompo = ({ goto, setLoginModal }: LoginCompo) => {
    const router = useRouter();
    const { updateCartDataContext, allRequestedProducts, updatePrsContext } = useBseCartContext();
    const { refPartnerInfo, mobileChangAble } = useRefPartnerApiContext();
    const userDeviceType = getDeviceType();

    const { setOpen, setIsOpen } = useLabTestContext();

    const [loading, setLoading] = useState(false);
    const [num, setNum] = useState(refPartnerInfo?.mobile ? refPartnerInfo?.mobile : '');
    const [otp, setOtp] = useState('');
    const [resend, setResend] = useState(true);
    const [clkResend, setClkResend] = useState(false);

    const intervalRef: any = useRef(null);
    const [timer, setTimer] = useState(30);
    const [referralCode, setReferralCode] = useState({
        refer: false,
        code: ''
    });

    const [verify, setVerify] = useState({
        status: false,
        message: '',
        dgt: null
    });

    const number = `+88${num}`;

    const setTimerFunction = () => {
        intervalRef.current = setInterval(() => {
            setTimer((prevState) => (prevState > 0 ? prevState - 1 : 0));
        }, 1000);
    };

    const handleSubmit = () => {
        if (validPhoneNumber(number)) {
            postAuthSMSSend(number, '', referralCode?.code)
                .then((res) => {
                    if (res.status === 'success') {
                        setVerify({ status: true, message: res?.message, dgt: res?.data?.otpDigitCount });
                        setTimerFunction();
                    } else {
                        toast.error(res?.message ? res?.message : 'Something Went Wrong!', { duration: 4000 });
                    }
                })
                .catch((error) => {
                    toast.error(error?.message ? error?.message : 'Something went wrong!', { duration: 4000 });
                })
                .finally(() => {
                    // console.log('f done');
                });
        } else {
            toast.error('Please enter valid number!', { duration: 4000 });
        }
    };

    const { showAlert } = useAlert();

    const handleLogin = async () => {
        if (!otp) {
            showAlert({ message: `Please Enter OTP` });
            return;
        }
        setLoading(true);
        try {
            const response = await postAuthSMSVerify(number, otp, '', referralCode?.code);
            if (response?.status === 'success') {
                // LAB TEST FUNCTIONALITY STARTS HERE
                setIsOpen((prev) => (prev = false));
                setOpen((prev) => (prev = Open.NONE));
                // LAB TEST FUNCTIONALITY ENDS HERE

                const { user, b2b_status, authToken } = response.data;
                const getUserDataWithPharmacy = (user: any, authToken: any, b2b_status: any) => {
                    return {
                        ...user,
                        authToken,
                        pharmacy_status: b2b_status?.status,
                        pharmacy_name: b2b_status?.pharmacy_name
                    };
                };

                const getUserDataWithoutPharmacy = (user: any, authToken: any) => {
                    return {
                        ...user,
                        authToken
                    };
                };
                const userData = user.u_pharmacy_id
                    ? getUserDataWithPharmacy(user, authToken, b2b_status)
                    : getUserDataWithoutPharmacy(user, authToken);

                await new Promise<void>((resolve) => {
                    localStorage.setItem(userStorageName, JSON.stringify(userData));
                    resolve();
                });

                setLoginModal && setLoginModal(false);

                allRequestedProducts === null ? updatePrsContext() : null;
                const getCartData = await updateUserCartRealTime();
                updateCartDataContext(getCartData);

                if (goto) {
                    router.push(goto);
                    router.refresh();
                } else {
                    router.refresh();
                }
            } else {
                toast.error(response?.message ? response?.message : 'Something went wrong!', { duration: 4000 });
            }
        } catch (error) {
            console.error('Error during login:', error);
        }
        setLoading(false);
    };

    const handleResend = () => {
        clearInterval(intervalRef.current);
        setTimer(30);
        setResend(true);
        setClkResend(true);
        handleSubmit();
    };

    useEffect(() => {
        if (timer < 1 && intervalRef.current) {
            clearInterval(intervalRef.current);
            setResend(false);
        }
    }, [timer]);

    useEffect(() => {
        if (refPartnerInfo) {
            if (refPartnerInfo?.showLogin === 'yes' && refPartnerInfo?.initialOTPSend === 'yes') {
                handleSubmit();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refPartnerInfo]);

    const min = Math.floor(timer / 60);
    const sec = (timer % 60).toString().padStart(2, '0');

    const handlePaste = (e: any) => {
        e.preventDefault();
        const pastedData = e.clipboardData.getData('text');
        if (pastedData.startsWith('+88')) {
            const numSplit = pastedData.split('+88');
            const newNUm = numSplit[1];
            setNum(newNUm);
        } else if (pastedData.startsWith('88')) {
            const numSplit = pastedData.split('88');
            const newNUm = numSplit[1];
            setNum(newNUm);
        } else {
            setNum(pastedData);
        }
    };

    return (
        <div className={styles.loginPage}>
            {loading ? <Loading overlayFixed /> : null}
            <div className={styles.loginContent}>
                <div className={`${styles.content_login} ${userDeviceType === 'ISO' ? styles.ios_label_input : ''}`}>
                    <h3>{!verify?.status ? 'Sign in to continue' : 'Verification'}</h3>
                    <p>Login/Register to make order,access your orders, special offers, health tips and more!</p>
                    {!verify?.status && (
                        <label htmlFor="mobileNum" className={!mobileChangAble ? styles.disable : ''}>
                            +88
                            <input
                                onChange={(e) => setNum(e.target.value)}
                                onPaste={(e) => handlePaste(e)}
                                value={num}
                                disabled={!mobileChangAble}
                                type="tel"
                                pattern="1[3-9]\d{8}"
                                required
                                maxLength={11}
                                id="mobileNum"
                                placeholder="Enter Mobile Number"
                            />
                        </label>
                    )}
                    {verify?.status && (
                        <p className={styles.verify_number}>
                            Enter the OTP sent to
                            <span>
                                <b>{number}</b>
                                {mobileChangAble && (
                                    <b
                                        onClick={() => {
                                            clearInterval(intervalRef.current);
                                            setTimer(30);
                                            setVerify({
                                                status: false,
                                                message: '',
                                                dgt: null
                                            });
                                        }}
                                    >
                                        Edit
                                    </b>
                                )}
                            </span>
                        </p>
                    )}

                    {verify?.status && (
                        <label htmlFor="verify">
                            <input
                                onChange={(e) => setOtp(e.target.value)}
                                type="text"
                                required
                                id="verify"
                                placeholder={
                                    !clkResend
                                        ? `Enter your ${verify?.dgt ? verify?.dgt : '4'} digit OTP`
                                        : `Enter Resend ${verify?.dgt ? verify?.dgt : '4'} digit OTP`
                                }
                            />
                        </label>
                    )}
                    {/* {!verify?.status && (
                        <p className={styles.p_color}>
                            By continuing you agree to our <Link href={R_TOS}>Terms of services,</Link>
                            <Link href={R_PRIVACY_POLICY}> Privacy Policy</Link> and&nbsp;
                            <Link href={R_RETURN_POLICY}>Return and Refund Policy.</Link>
                        </p>
                    )} */}
                    {verify?.status && (
                        <div className={styles.resend_code}>
                            <p>Haven&#39;t receive the code yet?</p>
                            <button type="button" disabled={resend} onClick={handleResend}>
                                {timer ? (
                                    <span>
                                        Resend Code <span className={styles.timer}>({sec}s)</span>
                                    </span>
                                ) : (
                                    'Resend Code!'
                                )}
                            </button>
                        </div>
                    )}
                    {referralCode?.refer && (
                        <div className="mt-10 mb-10">
                            <Input
                                type="text"
                                value={referralCode.code || ''}
                                placeholder="Enter Refer Code"
                                onChange={(e) => setReferralCode({ ...referralCode, code: e.target.value })}
                            />
                        </div>
                    )}
                    {!verify?.status ? (
                        <button type="button" className={`${styles.btn_mrg_top} ${styles.modalButton}`} onClick={() => handleSubmit()}>
                            Send
                        </button>
                    ) : (
                        <button type="button" className={styles.modalButton} onClick={() => handleLogin()}>
                            Verify
                        </button>
                    )}
                    <span
                        className={`${styles.ref}`}
                        onClick={() => setReferralCode((prevState) => ({ ...prevState, refer: !prevState.refer }))}
                    >
                        {referralCode?.refer ? "Didn't! have a referral code!" : 'Have a referral code?'}
                    </span>
                    <SocialLogin />
                    <p className={styles.p_color}>
                        By continuing you agree to our <Link href={R_TOS}>Terms of services,</Link>
                        <Link href={R_PRIVACY_POLICY}> Privacy Policy</Link> and&nbsp;
                        <Link href={R_RETURN_POLICY}>Return and Refund Policy.</Link>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default LoginCompo;
