import { useEffect, useState } from 'react';
import { getProviders, signIn, ClientSafeProvider } from 'next-auth/react';
import styles from './style.module.scss';
import { v1_apple, v1_google, v1_facebook, v1_twitter } from '@/services/utils/svg-large';
import { v1_linkedin } from '@/services/utils/svg';

const SocialLogin = () => {
    const [providers, setProviders] = useState<Record<string, ClientSafeProvider> | null>(null);

    useEffect(() => {
        const fetchProviders = async () => {
            const res = await getProviders();
            setProviders(res as Record<string, ClientSafeProvider>);
        };

        fetchProviders();
    }, []);

    if (!providers) {
        return;
    }

    return (
        <div className={styles.social_login_wrapper}>
            <div className={styles.top}>
                <hr />
                <span>or</span>
                <hr />
            </div>
            <div className={styles.login_buttons}>
                {Object.values(providers).map((provider) => (
                    <div key={provider.name}>
                        {provider.id === 'google' && <button onClick={() => signIn(provider.id)}>{v1_google}</button>}
                        {/* {provider.id === 'facebook' && <button onClick={() => signIn(provider.id)}>{v1_facebook}</button>} */}
                        {/* {provider.id === 'twitter' && <button onClick={() => signIn(provider.id)}>{v1_twitter}</button>} */}
                        {/* {provider.id === 'apple' && <button onClick={() => signIn(provider.id)}>{v1_apple}</button>} */}
                        {provider.id === 'linkedin' && <button onClick={() => signIn(provider.id)}>{v1_linkedin}</button>}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default SocialLogin;
