'use client';
import React, { createContext, useContext, useState, useEffect } from 'react';
import { isValidJSON, updateUserCartRealTime } from '../utils/clientHelper';
import { getAllProductRequestStock, postSocialLogin } from '../apis/api';
import { cartBseStorageName, isCouponExpireStorageName, lastOrderFromCartStorageName, userStorageName } from '../apis/config';
import toast from 'react-hot-toast';
import { signOut, useSession } from 'next-auth/react';
import useAuth from '../hooks/use-auth';
import Loading from '@/components/atoms/loading';
import { useRouter } from 'next/navigation';
import { createPortal } from 'react-dom';

const BseCartContext = createContext<any>(undefined);

export const BseCartProvider = ({ children }: any) => {
    const router = useRouter();
    const getUserFromLocalStorage = () => {
        const getData = typeof window !== 'undefined' ? localStorage.getItem(userStorageName) : null;
        return getData && isValidJSON(getData) ? JSON?.parse(getData) : {};
    };

    const [cartBseDataContext, setCartData] = useState<any>(null);
    const [allRequestedProducts, setAllRequestedProducts] = useState<any>(null);
    const [lastOrderFromCart, setLastOrderFromCart] = useState<any>(null);
    const [isCouponExpire, setIsCouponExpire] = useState<boolean>(false);

    useEffect(() => {
        const getData = async () => {
            const response = await getAllProductRequestStock();

            if (response?.status === 'success') {
                setAllRequestedProducts(response?.data);
            } else {
                setAllRequestedProducts([]);
                // toast.error(response?.message ? response?.message : 'Something went wrong!');
            }
        };
        if (getUserFromLocalStorage()?.authToken && allRequestedProducts === null) {
            getData();
        }
    }, [allRequestedProducts]);

    useEffect(() => {
        const data = localStorage.getItem(cartBseStorageName);
        const lastOrderFromLocalStorage = localStorage.getItem(lastOrderFromCartStorageName);
        const isCouponExpireStorage = localStorage.getItem(isCouponExpireStorageName);

        if (data && isValidJSON(data)) {
            setCartData(JSON.parse(data));
        }
        if (lastOrderFromLocalStorage && isValidJSON(lastOrderFromLocalStorage)) {
            setLastOrderFromCart(JSON.parse(lastOrderFromLocalStorage));
        }
        if (isCouponExpireStorage && isValidJSON(isCouponExpireStorage)) {
            const lastOrder = JSON.parse(lastOrderFromLocalStorage || 'false');
            if (lastOrder) {
                setIsCouponExpire(Boolean(JSON.parse(isCouponExpireStorage)));
            }
        }
    }, []);

    const updateCartDataContext = (newData: any) => {
        setCartData(newData);
        localStorage.setItem(cartBseStorageName, JSON.stringify(newData));
    };
    //
    const updateLastOrderFromCart = (newData: any) => {
        setLastOrderFromCart(newData);
        localStorage.setItem(lastOrderFromCartStorageName, JSON.stringify(newData));
    };

    const updateCouponExpire = (newData: boolean) => {
        setIsCouponExpire(newData);
        localStorage.setItem(isCouponExpireStorageName, JSON.stringify(newData));
    };

    const updatePrsContext = async () => {
        const response = await getAllProductRequestStock();

        if (response?.status === 'success') {
            setAllRequestedProducts(response?.data);
        } else {
            setAllRequestedProducts([]);
            // toast.error(response?.message ? response?.message : 'Something went wrong!');
        }
    };

    const socialLoginSession: any = useSession();
    const { user } = useAuth();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!user && socialLoginSession?.data) {
            setLoading(true);
            const { accessToken, provider } = socialLoginSession?.data;
            const handleSocialLogin = async () => {
                const res = await postSocialLogin({ provider, accessToken });
                if (res?.status === 'success') {
                    const { user, b2b_status, authToken } = res.data;
                    const getUserDataWithPharmacy = (user: any, authToken: any, b2b_status: any) => {
                        return {
                            ...user,
                            authToken,
                            pharmacy_status: b2b_status?.status,
                            pharmacy_name: b2b_status?.pharmacy_name
                        };
                    };

                    const getUserDataWithoutPharmacy = (user: any, authToken: any) => {
                        return {
                            ...user,
                            authToken
                        };
                    };
                    const userData = user.u_pharmacy_id
                        ? getUserDataWithPharmacy(user, authToken, b2b_status)
                        : getUserDataWithoutPharmacy(user, authToken);

                    await new Promise<void>((resolve) => {
                        localStorage.setItem(userStorageName, JSON.stringify(userData));
                        resolve();
                    });
                    allRequestedProducts === null ? updatePrsContext() : null;
                    const getCartData = await updateUserCartRealTime();
                    updateCartDataContext(getCartData);

                    // signOut();
                    router.refresh();
                    setLoading(false);
                } else {
                    setLoading(false);
                    toast.error(res?.message ? res?.message : 'Something went wrong!', { duration: 4000 });
                }
            };
            handleSocialLogin();
        }
    }, [allRequestedProducts, router, socialLoginSession?.data, user]);

    return (
        <BseCartContext.Provider
            value={{
                cartBseDataContext,
                updateCartDataContext,
                allRequestedProducts,
                setAllRequestedProducts,
                updatePrsContext,
                lastOrderFromCart,
                updateLastOrderFromCart,
                isCouponExpire,
                updateCouponExpire
            }}
        >
            {loading && createPortal(<Loading overlayFixed />, document.body)}
            {children}
        </BseCartContext.Provider>
    );
};

export const useBseCartContext = () => {
    const context = useContext(BseCartContext);
    if (!context) {
        throw new Error('useBseCart must be used within a BseCartProvider');
    }
    return context;
};
